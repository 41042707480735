<template>
    <b-card>
        <Form ref="createCategory" :submit="submit"/>
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions } from "vuex";

export default {
    name: "CreateCategory",

    components: {
        Form
    },

    methods: {
        ...mapActions({
           createCategory: 'category/create'
        }),
        submit() {
            const refCategory = this.$refs.createCategory
            refCategory.loading = true
            refCategory.errMessage = null

            const data = JSON.parse(JSON.stringify(refCategory.category))
            data.keywords = data.keywords.toString()
            this.createCategory(data).then(response => {
                refCategory.loading = false
                this.$router.push({ name: 'category' })
            }).catch(response => {
                refCategory.loading = false
                if (response.errors) {
                    refCategory.$refs.form.setErrors(response.errors)
                }
                refCategory.errMessage = response.message
                // refCategory.errMessage = this.$t('tags.' + response.tag)
            })

        }
    }
}
</script>
